var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.api.isError)?_c('AError',{attrs:{"api":_vm.api,"callbackReset":function () { return (_vm.api.isError = false); }}}):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('div',{staticClass:"form-content text-center"},[(_vm.data.length != 0)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":'Search ' + _vm.data.length + ' rows',"outlined":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]):_vm._e(),(_vm.data.length != 0)?_c('div',{staticClass:"text-right my-3 text-caption grey--text"},[_vm._v(" Showing "+_vm._s(_vm.page * 10 - 9)+" to "+_vm._s(_vm.page == _vm.totalPage ? _vm.data.length : _vm.page * 10)+" of "+_vm._s(_vm.data.length)+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.api.isLoading,"headers":_vm.headers,"items":_vm.data,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","search":_vm.search,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(_vm.getAvatar(item))?_c('AAvatar',{attrs:{"width":40,"height":40,"avatar":_vm.getAvatar(item).avatar}}):_c('v-img',{attrs:{"max-height":40,"max-width":40,"src":require('@/assets/CS0040_default_F0.png')}})]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.eventAction",fn:function(ref){
var item = ref.item;
return [(
            _vm.$moment() >= _vm.$moment(item.timestampStart) &&
            _vm.$moment() < _vm.$moment(item.timestampEnd)
            )?_c('div',{on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):(
            _vm.$moment() > _vm.$moment(item.timestampEnd)
            )?_c('div',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.handleClick(item)}}},[_vm._v("View Finishers")])],1):_c('div',[_c('v-icon',[_vm._v("mdi-lock")])],1)]}},{key:"item.eventDate",fn:function(ref){
            var item = ref.item;
return [_c('EventDate',{attrs:{"data":item}})]}},{key:"item.eventGamePoster",fn:function(ref){
            var item = ref.item;
return [_c('EventGamePoster',{attrs:{"data":item}})]}},{key:"item.eventStatus",fn:function(ref){
            var item = ref.item;
return [_c('EventStatus',{attrs:{"data":item}})]}},{key:"item.countStudent",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item._count.Student))])]}},{key:"item.schoolYear",fn:function(ref){
            var item = ref.item;
return [(item.schoolYear)?_c('div',[_vm._v(" "+_vm._s(_vm.$_.find(_vm.datasetSchoolYears, { id: item.schoolYear }).name)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.isActive",fn:function(ref){
            var item = ref.item;
return [_c('ColumnBoolean',{attrs:{"value":item.isActive,"textTrue":"Active","textFalse":"Inactive"}})]}},{key:"item.isTransferred",fn:function(ref){
            var item = ref.item;
return [_c('ColumnBoolean',{attrs:{"value":item.isTransferred}})]}},{key:"item.classroomName",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.Classroom.name)+" ")])]}},{key:"item.userAccountMobile",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.User.Account.mobile))])]}},{key:"item.userName",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.User.name)),(item.User.accountId == item.Institution.accountId)?_c('span',{staticClass:"pl-2"},[_c('v-chip',{attrs:{"color":"black","dark":"","x-small":""}},[_vm._v("Admin")])],1):_vm._e()])]}},{key:"item.positionName",fn:function(ref){
            var item = ref.item;
return [(item.Position && item.Position.translatableName)?_c('span',[_vm._v(_vm._s(JSON.parse(item.Position.translatableName)[_vm.$_getLocale()]))]):_c('span',[_vm._v("-")])]}},{key:"item.joinedStatus",fn:function(ref){
            var item = ref.item;
return [((item.User.Account.InvitedAccount[0] && item.User.Account.InvitedAccount[0].timestampPasswordChanged))?_c('span',[_vm._v(_vm._s(_vm.$moment(item.User.Account.InvitedAccount[0].timestampPasswordChanged).format("DD MMM YYYY hh:mm A")))]):(!item.User.Account.InvitedAccount[0])?_c('span',[_vm._v(_vm._s(_vm.$moment(item.timestampCreated).format("DD MMM YYYY hh:mm A")))]):_c('span',[_c('v-chip',{attrs:{"x-small":""}},[_vm._v("Pending Invite")])],1)]}}])}),(_vm.data.length != 0)?_c('div',{staticClass:"d-flex justify-center align-center pt-3"},[_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"text":"","small":"","color":"black","disabled":_vm.page == 1},on:{"click":function($event){return _vm.goFirstPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-page-first ")])],1)],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.page == 1,"small":"","color":"black"},on:{"click":function($event){return _vm.goPreviousPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-left ")])],1)],1),_c('div',{staticClass:"px-1"},[_c('v-menu',{attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-sm-flex",attrs:{"color":"black","rounded":"","width":"120px","text":""}},'v-btn',attrs,false),on),[_vm._v(" Page "+_vm._s(_vm.page)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-menu-down ")])],1),_c('v-btn',_vm._g(_vm._b({staticClass:"d-flex d-sm-none",attrs:{"fab":"","small":"","color":"black"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.page)+" ")])]}}],null,false,1555766714)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"200px"}},_vm._l((_vm.totalPage),function(p){return _c('v-list-item',{key:p,attrs:{"link":""},on:{"click":function($event){return _vm.selectPage(p)}}},[_c('v-list-item-title',[_vm._v("Page "+_vm._s(p))])],1)}),1)],1)],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"text":"","disabled":_vm.page == _vm.totalPage,"small":"","color":"black"},on:{"click":function($event){return _vm.goNextPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-right ")])],1)],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{attrs:{"disabled":_vm.page == _vm.totalPage,"text":"","small":"","color":"black"},on:{"click":function($event){return _vm.goLastPage()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-page-last ")])],1)],1)]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }