<template>
  <div>{{ $moment(data.timestampStart).format("DD/MM/YYYY") }} ~ {{ $moment(data.timestampEnd).format("DD/MM/YYYY") }}</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'data',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  };
</script>