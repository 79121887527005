<template>
<div v-if="data">
  <v-chip
    v-if="
      $moment() >= $moment(data.timestampStart) &&
      $moment() < $moment(data.timestampEnd)
    "
    small
    dark
    color="green"
  >{{$t("model.value.status.ongoing")}}</v-chip>
  <v-chip
    v-else-if="$moment(data.timestampEnd) < $moment()"
    small
    dark
    color="gray"
  >{{$t("model.value.status.past")}}</v-chip>
  <v-chip 
    v-else 
    small 
    dark 
    color="orange"
  >{{$t("model.value.status.coming")}}</v-chip>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      "data",
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>