<template>
  <v-img
    class="my-1"
    v-if="data.gameCode"
    contain
    width="100"
    :src="require('@/assets/Game/Poster/'+data.gameCode+'.png')"
    transition="fade-transition"
  ></v-img>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'data',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  };
</script>